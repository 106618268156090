import $ from 'jquery';
import suggestions from 'suggestions-jquery';
import Inputmask from 'inputmask';
import {
  validateForm,
  initPasswordEye,
} from '../validator/validator';
import {
  setStatus,
  setTextareaAutoHeight,
} from '../../utils/utils';

// Маска для ИНН
const innMask = new Inputmask('9{10,12}', {
  autoUnmask: true,
  showMaskOnHover: false,
  showMaskOnFocus: false,
  placeholder: '',
});

document.querySelectorAll('.input-inn').forEach((el) => {
  innMask.mask(el);
});

const setInputHeight = (field) => {
  field.style.height = `${field.scrollHeight}px`;
}

// Находим форму входа и навешиваем валидацию.
const lkUserForm = document.querySelector('#profile-form-user');
if (lkUserForm) {
  const submitButton = lkUserForm.querySelector('button[type="submit"]');

  lkUserForm.addEventListener('input', (evt) => {
    submitButton.removeAttribute('disabled');
  });

  validateForm('#profile-form-user');
}
const lkCompanyForm = document.querySelector('#profile-form-company');
if (lkCompanyForm) {
  const token = "e2eeb413a16819871f748c5de52aaaeee3059d46"; // мой личный ключ
  const innInput = lkCompanyForm.querySelector('#inn');
  const submitButton = lkCompanyForm.querySelector('button[type="submit"]');

  lkCompanyForm.addEventListener('input', (evt) => {
    submitButton.removeAttribute('disabled');
  });

  validateForm('#profile-form-company');

  const initInnHints = () => {
    if (innInput) {
      const kppInput = lkCompanyForm.querySelector('#kpp');
      const ogrnInput = lkCompanyForm.querySelector('#ogrn');
      const companyNameInput = lkCompanyForm.querySelector('#company-name');
      const companyShortNameInput = lkCompanyForm.querySelector('#company-short-name');
      const addressInput = lkCompanyForm.querySelector('#address');

      $('#inn').suggestions({
        token: token,
        type: 'PARTY',
        minChars: 8,
        formatResult: function (value, currentValue, suggestion, options) {

          const that = this;
          const highlightMatches = that.highlightMatches(suggestion.data.inn, currentValue, suggestion, options);

          return `${value}, ИНН: ${highlightMatches}`;
        },
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect: function (suggestion) {
          if (innInput) {
            innInput.value = suggestion.data.inn;
          }

          if (kppInput) {
            kppInput.value = suggestion.data.kpp;
            kppInput.nextElementSibling.value = kppInput.value;
          }

          if (ogrnInput) {
            ogrnInput.value = suggestion.data.ogrn;
            ogrnInput.nextElementSibling.value = ogrnInput.value;
          }

          if (companyNameInput) {
            companyNameInput.value = suggestion.data.name.full_with_opf;
            companyNameInput.nextElementSibling.value = companyNameInput.value;
            setInputHeight(companyNameInput.nextElementSibling);
          }

          if (companyShortNameInput) {
            companyShortNameInput.value = suggestion.data.name.short_with_opf;
            companyShortNameInput.nextElementSibling.value = companyShortNameInput.value;
            setInputHeight(companyShortNameInput.nextElementSibling);
          }

          if (addressInput) {
            addressInput.value = suggestion.data.address.value;
            addressInput.nextElementSibling.value = addressInput.value;
            setInputHeight(addressInput.nextElementSibling);
          }
        },

        /* Вызывается, когда пользователь не выбрал ни одну из подсказок */
        onSelectNothing: function () {
          if (kppInput) {
            kppInput.value = '';
            kppInput.nextElementSibling.value = kppInput.value;
          }

          if (ogrnInput) {
            ogrnInput.value = '';
            ogrnInput.nextElementSibling.value = ogrnInput.value;
          }

          if (companyNameInput) {
            companyNameInput.value = '';
            companyNameInput.nextElementSibling.value = companyNameInput.value;
            companyNameInput.nextElementSibling.style.height = '';
          }

          if (companyShortNameInput) {
            companyShortNameInput.value = '';
            companyShortNameInput.nextElementSibling.value = companyShortNameInput.value;
            companyShortNameInput.nextElementSibling.style.height = '';
          }

          if (addressInput) {
            addressInput.value = '';
            addressInput.nextElementSibling.value = addressInput.value;
            addressInput.nextElementSibling.style.height = '';
          }
        }
      });
    }
  }

  initInnHints();

  const initPostAdressHints = () => {
    const addressInput = lkCompanyForm.querySelector('#post-address');
    if (addressInput) {
      const errorHouse = addressInput.parentElement.parentElement.querySelector('.validator__error-container');
      setTextareaAutoHeight('#profile-form-company textarea');

      $('#fake-address').suggestions({
        token,
        type: 'ADDRESS',
        onSelect(suggestion) {
          if (!suggestion.data.house) {
            addressInput.value = '';
            errorHouse.innerHTML = '<div class="validator__error"">Укажите полный адрес</div>';

            return false;
          }
          if (suggestion.data.house) {
            addressInput.value = suggestion.value;
            errorHouse.innerHTML = '';
          }
        },
      });
    }
  };

  initPostAdressHints();
}